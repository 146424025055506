const React = require('react')
const PropTypes = require('prop-types')
const _ = require('lodash')
const types = require('../../types')
const Input = require('./semi-controlled')
const formIdConsumer = require('./form-id-consumer')
const connect = require('../connect')
const forms = require('../../styles/forms.css')
const {a11yOnly} = require('../../styles/global.css')

class GenericInput extends React.PureComponent {
  onChange(ev) {
    const {name, formId} = this.props
    this.props.dispatch({
      type: 'FORM_CHANGE',
      name,
      formId,
      value: ev.target.value,
    })
    this.props.onChange && this.props.onChange(ev)
  }

  componentDidMount() {
    const inputRef = this.props.inputRef || 'input'
    const valueObtainedFromRef = _.get(this.refs, [inputRef, 'value'])
    if (this.props.value || valueObtainedFromRef) {
      return
    }
    const {name, formId} = this.props
    this.props.dispatch({
      type: 'FORM_VALIDITY_CHECK',
      name,
      formId,
      errorMessage: null,
    })
  }

  render() {
    const {
      a11yOnlyLabel,
      autoComplete,
      className,
      textboxClassName,
      formData,
      icon,
      label,
      name,
      type,
      roleName,
      autoFocus,
      onBlur,
      onKeyUp,
      onKeyDown,
      onFocus,
      placeholder,
      inline,
      readonly,
      required,
      minLength,
      maxLength,
      disabled,
      inputMode,
      initialValue,
      inputref,
      isMandatory,
      isAlertErrorMessage = true,
      errorInLabel = true,
      trailingElement = null,
    } = this.props
    const {value = initialValue, errorMessage} = formData
    const valid = !errorMessage
    const id = this.props.formId + '_' + name
    const errorMessageRole = isAlertErrorMessage ? 'alert' : undefined

    const containerClass = className + (inline ? ` ${forms.inline}` : '')
    const inputClass = `${textboxClassName || ''} ${inline ? forms.textInputInline : forms.textInput}`
    const labelClass = inline ? forms.labelInline : forms.label
    const errorClass = inline ? forms.errorMessageInline : forms.errorMessage
    const errorId = errorMessage ? `${id}_error_message` : undefined

    return (
      <div className={`${containerClass}`}>
        <div className={`${icon ? forms.inputHasIcon : ''} nowrap flex justify-between`}>
          {label && (
            <label id={`${label}Id`} className={`${labelClass} ${a11yOnlyLabel ? a11yOnly : ''}`} htmlFor={id}>
              {label}
              {isMandatory && <abbr>*</abbr>}
            </label>
          )}
          {icon && <div className="pt1">{icon}</div>}
        </div>
        <Input
          autoFocus={autoFocus}
          id={id}
          aria-label={this.props['aria-label']}
          aria-controls={this.props['aria-controls']}
          aria-required={this.props['aria-required']}
          aria-invalid={!valid}
          aria-describedby={errorId}
          aria-expanded={this.props['aria-expanded']}
          aria-activedescendant={this.props['aria-activedescendant']}
          type={type}
          required={required}
          minLength={minLength}
          maxLength={maxLength}
          autoComplete={autoComplete}
          className={`${inputClass}  ${valid ? '' : forms.invalid}`}
          onChange={ev => this.onChange(ev)}
          onKeyUp={onKeyUp}
          onKeyDown={onKeyDown}
          onBlur={onBlur}
          onFocus={onFocus}
          name={name}
          value={value}
          readOnly={readonly}
          placeholder={placeholder}
          disabled={disabled}
          inputMode={inputMode}
          inputref={inputref}
          trailingElement={trailingElement}
          roleName={roleName}
        />
        {errorMessage &&
          (errorInLabel ? (
            <label id={errorId} htmlFor={id} className={errorClass} role={errorMessageRole}>
              {errorMessage}
            </label>
          ) : (
            <p id={errorId} className={errorClass} role={errorMessageRole}>
              {errorMessage}
            </p>
          ))}
      </div>
    )
  }
}

GenericInput.propTypes = {
  formId: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  autoComplete: PropTypes.string,
  formData: types.formDatum.isRequired,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  onFocus: PropTypes.func,
  required: PropTypes.bool,
  className: PropTypes.string,
  inline: PropTypes.bool,
  placeholder: PropTypes.string,
  a11yOnlylabel: PropTypes.bool,
  autoFocus: PropTypes.bool,
  isMandatory: PropTypes.bool,
  ariaDescribedby: PropTypes.string,
}

GenericInput.defaultProps = {
  formData: {
    value: '',
  },
  type: 'text',
  className: '',
  inline: false,
  placeholder: '',
  a11yOnlyLabel: false,
  required: false,
  autoFocus: false,
  isMandatory: false,
}

module.exports = connect()(formIdConsumer(GenericInput))
