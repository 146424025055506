'use strict'

const Link = require('@npm/spiferack/link')
const PropTypes = require('prop-types')
const React = require('react')
const UserIcon = require('../icons/user')
const CogIcon = require('../icons/cog')
const CreditCardIcon = require('../icons/credit-card')
const CoinsIcon = require('../icons/coins')
const BoxesIcon = require('../icons/boxes')
const styles = require('./sidebar.css')
const FileIcon = require('../icons/file')

class SettingsSidebar extends React.PureComponent {
  render() {
    const {memberships = {objects: []}, user, scope, auditLogEnabled} = this.props
    const hasMemberships = !!memberships.objects.length
    const scopeName = scope.parent.name

    return (
      <aside className={styles.sidebar}>
        <h2 className={styles.srOnly}>Sidebar</h2>
        <div className={styles.sticky}>
          <ul className={styles.list}>
            <li className={`${styles.listItem}`}>
              <Link>
                <a href={`/~${user.name}`} className={styles.link}>
                  <UserIcon /> Profile
                </a>
              </Link>
            </li>
            <li className={`${styles.listItem}`}>
              <Link>
                <a href={`/settings/${user.name}/packages`} className={styles.link}>
                  <BoxesIcon /> Packages
                </a>
              </Link>
            </li>
            <li className={`${styles.listItem}`}>
              <Link>
                <a href={`/settings/${user.name}/profile`} className={styles.link}>
                  <CogIcon /> Account
                </a>
              </Link>
            </li>
            <li className={`${styles.listItem}`}>
              <Link>
                <a href={`/settings/${user.name}/billing`} className={styles.link}>
                  <CreditCardIcon /> Billing Info
                </a>
              </Link>
            </li>
            <li className={`${styles.listItem}`}>
              <Link>
                <a href={`/settings/${user.name}/tokens`} className={styles.link}>
                  <CoinsIcon /> Access Tokens
                </a>
              </Link>
            </li>
            {auditLogEnabled && (
              <li className={`${styles.listItem} ${styles.auditLogLink}`}>
                <Link>
                  <a href={`/settings/${user.name}/audit-logs`} className={styles.link}>
                    <FileIcon /> Audit Logs
                  </a>
                </Link>
              </li>
            )}
          </ul>
          <div className={styles.header}>
            <h3 id="organizationsHeader">Organizations</h3>
            <Link>
              <a href="/org/create" className={styles.addLink}>
                <span aria-hidden="true">+</span>
                <span className={styles.srOnly}>Create New Organization</span>
              </a>
            </Link>
          </div>

          {hasMemberships ? (
            <ul aria-labelledby="organizationsHeader" className={styles.list}>
              {memberships.objects.map(uo => {
                const active = uo.org.name === scopeName
                return (
                  <li className={`${styles.listItem} ${active ? styles.active : ''}`} key={uo.org.name}>
                    <Link>
                      <a href={`/settings/${uo.org.name}/packages`} className={styles.link}>
                        <span />
                        <span>{uo.org.name}</span>
                      </a>
                    </Link>
                  </li>
                )
              })}
            </ul>
          ) : (
            <p>None</p>
          )}
        </div>
      </aside>
    )
  }
}

SettingsSidebar.propTypes = {
  memberships: PropTypes.shape({
    total: PropTypes.number,
    objects: PropTypes.array.isRequired,
  }).isRequired,
  user: PropTypes.shape({
    name: PropTypes.string.isRequired,
  }).isRequired,
}

module.exports = SettingsSidebar
